import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Managemet CMS',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'monitor',
    link: '/dashboard'
  },
  {
    label: 'Data Tim',
    icon: 'users',
    link: '/data-tim',
  },
  {
    label: 'Data Kategori Tim',
    icon: 'archive',
    link: '/data-kategori-tim',
  },
  {
    label: 'Data Pelamar',
    icon: 'user',
    link: '/data-pelamar',
  },
  {
    label: 'Data Lowongan Pekerjaan',
    icon: 'briefcase',
    link: '/data-lowongan-pekerjaan',
  },
  {
    label: 'Data Banner',
    icon: 'image',
    link: '/data-banner',
  },
];
